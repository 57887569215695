import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import VueCarousel from 'vue-carousel';
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css';
import '@mdi/font/css/materialdesignicons.css'
import router from './routes';
import './registerServiceWorker'

Vue.use(VueCarousel);
Vue.use(Vuetify)

const vuetifyOptions = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#3f51b5',
                secondary: '#7a3ca9',
                info: '#0195f7',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
})

Vue.config.productionTip = false


Vue.component('Top', () =>
    import ('./components/Top.vue'));

Vue.component('Footer', () =>
    import ('./components/Footer.vue'));

//accounts
Vue.component('bottom-navigation', () =>
    import ('./components/bottom_navigation.vue'));

Vue.component('account-profile', () =>
    import ('./components/accounts/profile.vue'));

Vue.component('account-password', () =>
    import ('./components/accounts/password.vue'));

Vue.component('account-privacy-and-security', () =>
    import ('./components/accounts/privacy_and_security.vue'));

new Vue({
    router,
    render: h => h(App),
    vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
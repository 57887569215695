<template>
  <v-app
    :light="!dark"
    :dark="dark"
  > 
    <v-content>
      <router-view />
    </v-content>
   
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      dark: false
    };
  }
};
</script>

<style>
#app {
  font-family: 'M PLUS Rounded 1c', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
#app a{
  text-decoration: none;
}
a {
    color: #f5b20c !important;
}

.a-login {
    color: white !important;
}
</style>
import Vue from 'vue';
import Router from 'vue-router';

const requireAuth = async(to, from, next) => {
    next();
};


const routes = [];
routes.push({
    path: '/',
    component: () =>
        import ('./pages/Login.vue'),
    beforeEnter: requireAuth
});
routes.push({
    path: '/login',
    component: () =>
        import ('./pages/Login.vue'),
    beforeEnter: requireAuth
});
routes.push({
    path: '/password/rest',
    component: () =>
        import ('./pages/Password_rest.vue'),
    beforeEnter: requireAuth
});
routes.push({
    path: '/signup',
    component: () =>
        import ('./pages/Signup.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/time_line',
    component: () =>
        import ('./pages/Time_line.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/search',
    component: () =>
        import ('./pages/Search.vue'),
    beforeEnter: requireAuth
});



routes.push({
    path: '/account',
    component: () =>
        import ('./pages/Account.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/account_another',
    component: () =>
        import ('./pages/Account_another.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/accont_edit',
    component: () =>
        import ('./pages/Account_edit.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/messages',
    component: () =>
        import ('./pages/Messages.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/message',
    component: () =>
        import ('./pages/Message.vue'),
    beforeEnter: requireAuth
});


Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});